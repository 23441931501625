var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { attrs: { id: "cart" } },
    [
      _c("div", { staticClass: "cart-title card-title mt-0" }, [
        _vm._v("\n    Order Summary\n  "),
      ]),
      _c(
        "b-card-text",
        {},
        [
          _vm.cartEmpty
            ? _c("em", [_vm._v("\n      Your cart is empty.\n    ")])
            : _c("cart-item-list", { attrs: { dashed: false } }),
        ],
        1
      ),
      _c("hr"),
      !_vm.cartEmpty
        ? _c(
            "div",
            { staticClass: "d-flex flex-row justify-content-between total" },
            [
              _c("div", [
                _c("strong", [
                  _vm.cartTypeScope === "LegalSpec"
                    ? _c("span", [_vm._v("\n          Estimated\n        ")])
                    : _vm._e(),
                  _vm._v("\n        Total\n      "),
                ]),
              ]),
              _vm.cartTypeScope === "LegalSpec"
                ? _c("div", [
                    _c("strong", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("dollars")(_vm.legalCartItemsTotal)) +
                          "\n      "
                      ),
                    ]),
                  ])
                : _c("div", [
                    _c("strong", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("dollars")(_vm.cartItemsTotal)) +
                          "\n      "
                      ),
                    ]),
                  ]),
            ]
          )
        : _vm._e(),
      !_vm.hideButton
        ? _c("b-form-group", [
            _c(
              "div",
              { staticClass: "checkout-button-group" },
              [
                _vm.showPromoCode
                  ? _c("promo-code", {
                      staticClass: "promo-code",
                      attrs: {
                        alignment: "left",
                        "checkout-in-progress": _vm.localProcessingCheckout,
                      },
                    })
                  : _vm._e(),
                _c("payment-ssl"),
                !_vm.localProcessingCheckout
                  ? _c(
                      "b-button",
                      {
                        staticClass: "cart-button float-right btn-preferred",
                        attrs: {
                          "aria-label": _vm.buttonText + " button",
                          disabled:
                            _vm.cartEmpty ||
                            _vm.localProcessingCheckout ||
                            _vm.isAgreementAccepted,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rootEmit("cart-next-page")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.buttonText) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.localProcessingCheckout
        ? _c("ct-centered-spinner", { staticClass: "mb-0" })
        : _vm._e(),
      _c("collect-account-info", {
        on: {
          success: function ($event) {
            return _vm.rootEmit("cart-proceed-to-payment")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }